.slick-dots {
    position: absolute;
    bottom: 10px; /* Adjust this value as needed to position the dots above the image */
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
}

.slick-dots li button:before {
    font-size: 12px; /* Adjust the size of the dots if needed */
    color: white; /* Color of the dots */
}

.slick-dots li.slick-active button:before {
    color: red; /* Color of the active dot */
}
