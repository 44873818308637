@media (min-width: 1025px) {
    .h-custom {
      height: 100vh !important;
    }
  }
  
  .card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
  }
  
  .card-registration .select-arrow {
    top: 13px;
  }
  
  .bg-grey {
    background-color: #eae8e8;
  }
  @media (max-width: 576px) {
    .text-end {
      text-align: left; /* Align price text to the left on mobile for better readability */
    }
  }
  
  .mb-2 {
    margin-bottom: 0.5rem; /* Adjust spacing between elements */
  }
  
  
  