.category-slider {
  padding: 7px 0;
  position: relative;
  z-index: 10; /* Ensures the category slider stays above other elements */
  background: #db732e2e;
}
.slick-list {
  overflow: visible !important; /* Keep default overflow behavior */
}


.items {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-right: 1px solid #ccc; */
  min-width: 100px;
}

/* Adjust min-width for very small screens */
@media (max-width: 480px) {
  .items {
    min-width: 160px; /* Further reduce min-width for very small screens */
  }
}


/* .slick-slide:not(:last-child) .items {
  border-right: 1px solid #ccc;
} */

.catImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.catImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

a {
  text-decoration: none;
  /* margin-top: 10px; */
  /* padding: 5px; */
  color: #000;
}

.dropdown-content {
  display: block; 
  position: absolute; /* Position relative to parent */
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  /* padding: 12px 16px; */
  z-index: 1000;
  border-radius: 4px;
  min-width: 150px;
  border-radius: 10px;
}
.items:hover .dropdown-content {
  overflow: visible;
  display: block; 
}
html, body {
  max-height: 100vh; /* Limit the page height to the viewport */
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: auto; /* Ensure scrolling works for long content */
}

.dropdown-content a {
  display: block;
  padding: 10px 0; /* Adds space above and below each link */
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #c25252; /* Optional for a subtle divider between links */
}

.dropdown-content a:last-child {
  border-bottom: none; /* Remove the border from the last item */
}

.dropdown-content a:hover {
  border-radius: 10%;
  background: #f8e5e5; /* Change background color on hover */
}


/* Style for the hero slider below */
.hero-slider {
  position: relative;
  z-index: 1; /* Lower than the category slider to avoid overlap */
}
.dropdown-icon {
  margin-left: 10px; /* Add some space between the text and the icon */
  font-size: 16px;   /* Adjust the size of the dropdown icon if needed */
  color: #bb2e2e;
}  
.category-slider .slick-next {
  right: 20px;
}
.category-slider .slick-prev {
  left: 20px;
  z-index: 10;
}
.category-slider .slick-arrow:before{
  color: #c14f4f;
}
.category-slider .slick-next.slick-disabled:before, .slick-prev.slick-disabled:before {
  opacity: 0;
}