.addtocartbtn{
    border-radius: 4px;
    margin-top: 0px;
    background-color: #c14f4f;
    border: none;
    min-height: 2.4rem;
    padding: 0px;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    width: 100%;
    margin-top: 0.5rem;
    padding: 0px 0.8rem;
}