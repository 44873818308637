.productSlider {
    text-align: center;
    min-height: 402px;
    height: auto;
}

.productSlider .slick-arrow:before {
    color: #c14f4f;
    font-size: 30px;
}

.productSlider .slick-prev {
    left: 1px;
    z-index: 10;
}

.centered-heading {
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
}

.productBox .productImage img {
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 8px;
    margin: auto;
    width: 100%;
    aspect-ratio: 306 / 200;
}

.productBox .productImage {

    width: 100%;
    text-align: center;
    flex-direction: column;
    height: auto;
    background-color: white;
    opacity: 1;
    pointer-events: auto;
    gap: 0.4rem;
}

.productBox {
    outline: none;
    border-radius: 8px;
    padding: 8px;

}

.productBox .productTitle {
    margin-top: 0.5rem;
    text-decoration: none;
    font-family: "Fira Sans", sans-serif;
    text-align: left;
    text-transform: capitalize;
    width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: rgb(19, 20, 21);
}

.productBox .productDesc {
    text-align: left;
    font-family: "Fira Sans", sans-serif;
    margin-top: 0.5rem;
    color: rgb(133, 133, 133);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.slick-next {
    right: 0;
}

.productBox .productPrice {
    text-align: left;
    gap: 8px;
    text-decoration: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: baseline;
    align-items: baseline;
    font-family: "Fira Sans", sans-serif;
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.5px;
    margin-top: 0.5rem;
    color: rgb(19, 20, 21);
}

.productBox button {
    border-radius: 4px;
    margin-top: 0px;
    background-color: #c14f4f;
    border: none;
    min-height: 2.4rem;
    padding: 0px;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    width: 100%;
    margin-top: 0.5rem;
    padding: 0px 0.8rem;
}